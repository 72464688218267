import React from 'react';
import styled from 'styled-components';
import { Link, StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import media from '../layout/Media';

const Foot = styled.footer`
  grid-area: foot;
  color: #333;
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;

  background: ${props => props.theme.lightGrey};
  @media ${media.tablet} {
    padding: 1.5rem 3rem;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;

const FooterColumn = styled.div`
  margin-top: 1.5rem;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 20%;
  margin-right: 2%;
  h4 {
    margin-bottom: 10px;
  }
`;

const FooterLink = styled(Link)`
  display: block;
  font-size: 0.9rem;
  color: #333;
  margin-bottom: 10px;
`;

/**
 * Component for showing the footer on the website.  The About section is auto generating from the About pages in DatoCMS
 */
const Footer = () => (
  <StaticQuery
    query={graphql`
      query {
        allDatoCmsAbout(filter: { locale: { eq: "en" } }) {
          edges {
            node {
              title
              slug
            }
          }
        }
      }
    `}
    render={data => (
      <Foot>
        <FooterColumn className="footcol1">
          <h4>Quick Links</h4>
          <FooterLink to="/">Home</FooterLink>
          <FooterLink to="/hostels/">Locations</FooterLink>
          <FooterLink to="/groups/">Group Bookings</FooterLink>

          <FooterLink to="/deals/">Deals</FooterLink>
          <FooterLink to="/blog/">Guides</FooterLink>
        </FooterColumn>
        <FooterColumn className="footcol3">
          <h4>About us</h4>
          {data.allDatoCmsAbout.edges.map(({ node }, index) => (
            <FooterLink key={index} to={`/about/${node.slug}/`}>
              {node.title}
            </FooterLink>
          ))}
        </FooterColumn>
        <FooterColumn className="footcol4">
          <h4>Contact us</h4>
          <div>
            <strong>Email:</strong> info@stayatbase.com
          </div>
          <div>
            <strong>Tel:</strong> AU 1800 24 22 73 or
          </div>
          <div> NZ 0800 22 73 69</div>
          <br />
          <div>Copyright Base Backpackers 2018.</div>
        </FooterColumn>
      </Foot>
    )}
  />
);

export { Footer };

Footer.propTypes = {
  /** Is the text in the Link */
  title: PropTypes.string,
  key: PropTypes.number
};
