import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';
import styled from 'styled-components';
import SiteLogo from '../base-logo-white.png';
import SiteLogoDark from '../base-logo.png';
import { Button } from '../common';
import { Nav, MenuBooking } from '../layout/index.js';

import useTranslations from './useTranslations';
import LocalisedLink from './locallisedLink';

const Logo = styled.div`
  position: relative;
`;

const LogoWrapper = styled.div`
  position: absolute;
  top: 10px;
  left: 0px;
  width: 110px;
  @media (max-width: 900px) {
    left: 0;
    top: 10px;
  }
`;

const Menu = styled.div`
  text-align: left;
  padding: 0.8rem 0 0.8rem 0.8rem;
  justify-self: end;
  float: right;
`;

const LocalisedNavLink = styled(LocalisedLink)`
  display: inline-block;
  text-align: left;
  padding: 5px 10px;
  text-decoration: none;
  font-size: 1rem;
  float: left;
`;
const NavLink = styled(Link)`
  display: inline-block;
  text-align: left;
  padding: 5px 10px;
  text-decoration: none;
  font-size: 1rem;
  float: left;
`;

const NavButton = styled.a`
  float: left;
  display: inline-block;
  color: #f2f2f2;
  text-align: center;
  padding: 0px 10px;
  text-decoration: none;
  font-size: 1rem;
`;

const MenuSpan = styled.span`
  font-weight: bold;
  font-size: 1.3rem;
`;

/** The main navigation component for the site */
const Navigation = props => {
  const [showMenu, setShowMenu] = useState(false);
  const [showDraw, setShowDraw] = useState(false);

  const toggleDraw = () => {
    setShowDraw(!showDraw);
  };
  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  //translations

  const {
    locations,
    groups,
    deals,
    guides,
    social,
    work,
    bookNow,
    menu
  } = useTranslations();

  const burgerActive = showMenu ? 'responsive' : '';
  return (
    <Nav darkmenu={props.darkmenu}>
      <MenuBooking open={showDraw} toggleDraw={toggleDraw} />
      <Logo>
        <LogoWrapper>
          <LocalisedLink to="/">
            {props.darkmenu ? (
              <img
                src={SiteLogoDark}
                style={{ width: 110 }}
                alt="Base Backpackers Australia and new zealand"
              />
            ) : (
              <img
                src={SiteLogo}
                style={{ width: 110 }}
                alt="Base Backpackers Australia and new zealand"
              />
            )}
          </LocalisedLink>
        </LogoWrapper>
      </Logo>
      <Menu id="Topnav" className={`topnav ${burgerActive} `}>
        {/* <NavLink to="/destinations/">Destinations</NavLink> */}
        <LocalisedNavLink className="amlink" to="/hostels/">
          {locations}
        </LocalisedNavLink>
        <LocalisedNavLink className="amlink" to="/groups/">
          {groups}
        </LocalisedNavLink>
        <NavLink className="amlink" to="/deals/">
          {deals}
        </NavLink>
        <NavLink className="amlink" to="/blog/">
          {guides}
        </NavLink>

        <div className="dropdown">
          <button className="dropbtn amlink">{social}</button>
          <div className="dropdown-content">
            <a
              href="https://www.instagram.com/basehostels/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Instagram
            </a>

            <a
              href="https://www.facebook.com/BaseBackpackers/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Facebook
            </a>
            <a
              href="https://www.pinterest.com/basebackpackers/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Pinterest
            </a>
          </div>
        </div>
        <div className="dropdown ">
          <button className="dropbtn amlink">{work}</button>
          <div className="dropdown-content">
            <Link to="/work/">Work & Travel Packages</Link>
            <Link to="/careers/">Base Careers</Link>
            <Link to="/intern/">Internships</Link>
          </div>
        </div>

        {props.hostel ? (
          <Button primary className="menucta distributor">
            {bookNow}
          </Button>
        ) : (
          <Button primary className="menucta" onClick={toggleDraw}>
            {bookNow}
          </Button>
        )}

        <NavButton className="icon" href="#" onClick={toggleMenu}>
          <MenuSpan>{menu}</MenuSpan> &#9776;
        </NavButton>
      </Menu>
    </Nav>
  );
};

Navigation.propTypes = {
  /** Switches between a dark and light menu. Supplied by header component */
  darkmenu: PropTypes.bool,
  /** Determins if this is a hostel page or not if it is it will change the action of the booking button */
  hostel: PropTypes.bool
};

export { Navigation };
