/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
require('typeface-pt-sans');
import CustomLayout from './wrapPageElement';
export const wrapPageElement = CustomLayout;
