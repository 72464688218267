export default function(queryString) {
  var params = {},
    queries,
    temp,
    i,
    l;
  // Split into key/value pairs

  let newQueryString = queryString.substring(1);
  queries = newQueryString.split('&');
  // Convert the array of strings into an object
  for (i = 0, l = queries.length; i < l; i++) {
    temp = queries[i].split('=');
    params[temp[0]] = temp[1];
  }
  return params;
}
