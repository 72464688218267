import React, { Component, useState, useEffect } from 'react';
import styled from 'styled-components';
import Link from 'gatsby-link';
import PropTypes from 'prop-types';
import media from '../layout/Media';
import DatePicker from 'react-datepicker';

import parse from 'date-fns/parse';
import differenceInCalendarDays from 'date-fns/difference_in_calendar_days';
import 'react-datepicker/dist/react-datepicker.css';

import useTranslations from '../layout/useTranslations';

import { Button } from '../common/';
import { FromTag } from '../layout/';
import {
  FormGroup,
  FormLabel,
  Input,
  TextArea,
  Optional,
  FormGroup2col,
  Select
} from '../layout/FormStyles';
import LocalisedLink from './locallisedLink';

const FormBox = styled.div`
  flex-grow: 1;
  flex-wrap: 1;
  flex-basis: 45%;
  margin-bottom: 1rem;
  form {
    padding: 1.5rem;
    background: white;
    margin-top: 2rem;
    margin-bottom: 0;
    position: relative;

    @media ${media.tablet} {
      padding: 2.5rem;
      margin-top: 0rem;
    }
  }
  label,
  p,
  h2 {
    color: ${props => props.theme.black};
  }

  @media ${media.tablet} {
    grid-template-columns: 1fr;
  }
  .DayPicker-Day,
  .DayPicker-Caption {
    color: #333;
  }
  .DayPickerInput input {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
`;

const Small = styled.p`
  font-size: 0.8rem;
  margin-bottom: 0;
`;

/** The form for the groups page */
const GroupsForm = props => {
  const [start, setStart] = useState('');
  const [end, setEnd] = useState('');
  const [pax, setPax] = useState(0);
  const [value, setValue] = useState(0);
  const [buttonDisabled, setbuttonDisabled] = useState(true);

  const diffDays = () => {
    const diff = differenceInCalendarDays(parse(end), parse(start));
    let bookingValue = (diff * props.priceFrom * pax) / 100;
    setValue(bookingValue);
    return;
  };

  const handleChangeStart = date => {
    setStart(date);
  };

  const handleChangeEnd = date => {
    setEnd(date);
  };

  const calcPaxNum = e => {
    setPax(e.target.value);
    if (e.target.value > 10) {
      setbuttonDisabled(false);
    }
  };

  useEffect(
    () => {
      diffDays();
    },
    [handleChangeEnd, handleChangeStart, calcPaxNum]
  );

  const onFormSuccess = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'formSubmissionSuccess',
      formName: 'Groups Form',
      propertyName: props.propertyName,
      eventValue: value
    });
    return;
  };

  //lang variables,
  const {
    enquire,
    bookWithCard,
    required,
    firstName,
    lastName,
    yourEmail,
    noShare,
    yourNumber,
    details,
    arrival,
    departure,
    paxNum,
    groupType,
    requirements,
    bookIt,
    schoolGroup,
    sportGroup,
    hensGroup,
    others,
    requirementsPlaceholder
  } = useTranslations();

  return (
    <FormBox>
      <form
        name="Group Booking form"
        method="POST"
        data-netlify="true"
        onSubmit={onFormSuccess}
        netlify-honeypot="bot-field"
      >
        {props.priceFrom && <FromTag priceFrom={props.priceFrom} />}
        <h2>{enquire}</h2>
        <span
          style={{
            fontSize: '10px',
            color: 'red',
            textAlign: 'right',
            display: 'block'
          }}
        >
          * {required}
        </span>
        <Input
          id="propertyName"
          name="propertyName"
          type="hidden"
          value={props.propertyName}
          readOnly
        />
        <Input
          id="source"
          name="source"
          type="hidden"
          value={props.source}
          readOnly
        />

        {/* Hidden form variables below */}
        <input type="hidden" name="form-name" value="Group Booking form" />
        <FormGroup2col col="2" mobcol="1fr" gap=".3rem">
          <div>
            <FormLabel htmlFor="fname">
              {firstName}:<span className="required">*</span>
            </FormLabel>
            <Input
              id="fname"
              type="text"
              name="firstName"
              placeholder="e.g Bob"
              required
            />
          </div>
          <div>
            <FormLabel htmlFor="lname">
              {lastName}
              <span className="required">*</span>
            </FormLabel>
            <Input
              id="lname"
              type="text"
              name="lastName"
              placeholder="e.g Smith"
              required
            />
          </div>
        </FormGroup2col>
        <FormGroup>
          <FormLabel htmlFor="email">
            {yourEmail}:<span className="required">*</span>{' '}
          </FormLabel>
          <Input
            id="email"
            type="email"
            name="email"
            placeholder="e.g bob@bob.com"
            required
          />

          <Small>{noShare}</Small>
        </FormGroup>
        <FormGroup>
          <FormLabel htmlFor="phone">
            {yourNumber}: <Optional>Optional</Optional>
          </FormLabel>
          <Input
            id="phone"
            type="phone"
            name="phone"
            placeholder="e.g +61 420 420 420"
          />
          <Small>{details}</Small>
        </FormGroup>

        <FormGroup2col col="2" gap=".5rem">
          <div>
            <FormLabel htmlFor="NumberPax">
              {arrival}
              <span className="required">*</span>
            </FormLabel>
            <DatePicker
              selected={start}
              selectsStart
              startDate={start}
              endDate={end}
              dateFormat="dd/MM/yyyy"
              onChange={handleChangeStart}
              placeholderText={arrival}
              minDate={new Date()}
              required
            />
          </div>
          <span className="InputFromTo-to">
            <FormLabel htmlFor="NumberPax">
              {departure}
              <span className="required">*</span>
            </FormLabel>

            <DatePicker
              selected={end}
              selectsEnd
              dateFormat="dd/MM/yyyy"
              startDate={start}
              endDate={end}
              onChange={handleChangeEnd}
              placeholderText={departure}
              minDate={new Date()}
              required
            />
          </span>
        </FormGroup2col>
        <FormGroup>
          <FormLabel htmlFor="NumberPax">
            {paxNum}
            <span className="required">*</span>
          </FormLabel>
          <Input
            id="NumberPax"
            type="number"
            name="numberOfPeople"
            required
            min="10"
            placeholder="e.g 10"
            onChange={calcPaxNum}
          />
          <Small>
            {bookWithCard}
            <LocalisedLink to={`hostels/${props.link}`}> book online here</LocalisedLink>
            
          </Small>
        </FormGroup>
        <FormGroup>
          <FormLabel htmlFor="groupType">
            {groupType}
            <span className="required">*</span>
          </FormLabel>
          <Select id="groupType" name="groupType" required>
            <option value="School Group">{schoolGroup}</option>
            <option value="Sport Group">{sportGroup}</option>
            <option value="Hens/Stag Party">{hensGroup}</option>
            <option value="Other">{others}</option>
          </Select>
        </FormGroup>
        <FormGroup>
          <FormLabel htmlFor="requirements">{requirements}:</FormLabel>
          <TextArea
            id="requirements"
            name="requirements"
            placeholder={requirementsPlaceholder}
          />
        </FormGroup>
        <input name="bookingValue" type="hidden" value={value} />
        <input name="arrival" type="hidden" value={start} />
        <input name="departure" type="hidden" value={end} />

        <input
          name="groupManager"
          type="hidden"
          value={props.data.groupsManagerName}
        />
        <input
          name="groupManagerEmail"
          type="hidden"
          value={props.data.groupsManagerEmail}
        />

        <p style={{ display: 'none' }}>
          <label>
            Don’t fill this out if you're human: <input name="bot-field" />
          </label>
        </p>
        <FormGroup>
          <Button primary type="submit">
            {bookIt}
          </Button>
        </FormGroup>
      </form>
    </FormBox>
  );
};

export { GroupsForm };

GroupsForm.propTypes = {
  /** Links back to the main hostel page for small group bookings  */
  link: PropTypes.string,
  /** The name of the hostel associated with the group booking page */
  propertyName: PropTypes.string.isRequired,
  /** The number in cents of the price of a bed for a group */
  priceFrom: PropTypes.number
};
