import React from 'react';
import Scrollspy from 'react-scrollspy';
import styled from 'styled-components';
import { Section, Container } from '../common/';
import media from '../layout/Media';
import useTranslations from '../layout/useTranslations';

const NavItem = styled.li`
  padding-left: 0;
  display: inline-block;
  margin-right: 1rem;
  margin-bottom: 0rem;
  padding-bottom: 0.7rem;
  font-size: 0.9rem;
  &:hover {
    border-bottom: 2px solid ${props => props.theme.secondaryColor};
  }
  @media ${media.mobileL} {
    margin-right: 1.3rem;
  }
`;

const NavButton = styled(NavItem)`
  background: ${props => props.theme.primaryColor};
  padding: 0.5rem;
  color: ${props => props.theme.white};
  cursor: pointer;
  &:hover {
  }
  display: none;
  @media ${media.mobileL} {
    display: inline-block;
  }
`;

const NavItemLink = styled.a`
  color: inherit;
  text-decoration: none;
  &:hover {
    color: ${props => props.theme.secondaryColor};
  }
`;

const StickySection = styled(Section)`
  padding: 1.5rem 1.5rem 0 1.5rem;
  position: sticky;
  top: 0;
  z-index: 3;
  @media ${media.tablet} {
    padding: 2rem 3rem 1rem;
  }
`;

/** Sticky Nav compnent for the hostel pages sub menu */
const StickyNav = props => {
  const {
    bookNow,
    rooms,
    activities,
    facilities,
    location,
    faq,
    deals
  } = useTranslations();

  return (
    <StickySection>
      <Container>
        <Scrollspy
          className="ScrollNav"
          items={['rooms', 'activities', 'facilities', 'location', 'faq']}
          currentClassName="is-current"
        >
          <NavItem>
            <NavItemLink href="#rooms">{rooms}</NavItemLink>
          </NavItem>
          {props.deal && (
            <NavItem>
              <NavItemLink href="#deal">{deals}</NavItemLink>
            </NavItem>
          )}
          <NavItem>
            <NavItemLink href="#activities">{activities}</NavItemLink>
          </NavItem>
          <NavItem>
            <NavItemLink href="#facilities">{facilities}</NavItemLink>
          </NavItem>
          <NavItem>
            <NavItemLink href="#location">{location}</NavItemLink>
          </NavItem>
          <NavItem>
            <NavItemLink href="#faq">{faq}</NavItemLink>
          </NavItem>
          {props.mewsProperty && (
            <NavButton primary className="menucta distributor">
              {bookNow}
            </NavButton>
          )}
        </Scrollspy>
      </Container>
    </StickySection>
  );
};

export { StickyNav };
