import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { LocaleContext } from '../layout';

function useTranslations() {
  // Grab the locale (passed through context) from the Context Provider
  const { locale } = React.useContext(LocaleContext);

  // Query the JSON files in <rootDir>/i18n/translations
  const { rawData } = useStaticQuery(query);

  // Simplify the response from GraphQL
  const simplified = rawData.edges.map(item => {
    return {
      name: item.node.name,
      translations: item.node.translations
    };
  });

  // Only return translations for the current locale
  const { translations } = simplified.filter(lang => lang.name === locale)[0];

  return translations;
}

export default useTranslations;

const query = graphql`
  query useTranslations {
    rawData: allFile(filter: { sourceInstanceName: { eq: "translations" } }) {
      edges {
        node {
          name
          translations: childTranslationsJson {
            subline
            bookNow
            viewGallery
            rooms
            activities
            facilities
            location
            faq
            stay
            stayfrom
            includes
            checkAvail
            bestPrice
            socialSpot
            activtySub
            daytime
            nighttime
            monday
            tuesday
            wednesday
            thursday
            friday
            saturday
            sunday
            thingsNearby
            locations
            groups
            deals
            guides
            social
            work
            menu
            phone
            email
            address
            ourRooms
            plan
            formFill
            bookWithCard
            required
            firstName
            lastName
            yourEmail
            noShare
            yourNumber
            details
            arrival
            departure
            paxNum
            groupType
            requirements
            bookIt
            phoneInstead
            enquire
            groupFacilities
            english
            chinese
            findWorld
            experience
            whereTo
            letsGo
            sunset
            baseYourself
            looksLike
            Inspiration
            australianHostels
            nzHostels
            groupsTitle
            groupsBlurb
            coordinatorBlurb
            coordinatorTitle
            knowHow
            reasons
            schoolGroup
            sportGroup
            hensGroup
            others
            requirementsPlaceholder
            privateCTA
            dormCTA
            perPerson
          }
        }
      }
    }
  }
`;
