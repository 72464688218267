import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import React from 'react';
import styled from 'styled-components';
import { Button } from '../common';
import media from './Media';
import useTranslations from './useTranslations';

const FlexContainer = styled.div`
  display: flex;
  max-width: ${props => props.theme.maxwidth};
  margin: 0 auto;
  flex-wrap: wrap;
  flex-direction: row;
`;

const DealCard = styled.div`
  background: ${props => props.theme.white};
  position: relative;

  margin-right: 2rem;
  display: inline-block;

  flex: 1 1 40%;
  h3 {
    color: ${props => props.theme.secondaryColor};
    max-width: 90%;
    line-height: 1.3;
  }
`;

/**
 * This component shows the accommodation info on the hostel page including the gallery, pricing, booking button
 */
const Deal = ({ dealData }) => {
  console.log(dealData);
  return (
    <FlexContainer>
      {dealData.map(deal => (
        <DealCard key={deal.id}>
          {deal.promo && (
            <Img
              alt={deal.promo.alt}
              fluid={deal.promo.fluid}
              style={{ marginBottom: '1rem' }}
            />
          )}
          <h3>{deal.title}</h3>

          <div dangerouslySetInnerHTML={{ __html: deal.description }} />

          <Button className="distributor" primary>
            Book now
          </Button>
        </DealCard>
      ))}
    </FlexContainer>
  );
};

Deal.propTypes = {};
export { Deal };
