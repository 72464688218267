import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Img from 'gatsby-image';
import media from '../layout/Media';

import { Section, Container } from '../common/';
import { Navigation, HostelSearchForm } from '../layout/';

import LangSwitch from './LangSwitch';

const HeaderContainer = styled(Container)`
  z-index: 1;
  position: relative;
  color: ${props => props.theme.white};
  margin-top: 5rem;
  max-width: none;
`;

const PageTitle = styled.h1`
  width: 100%;
  font-size: 2.23rem;
  line-height: 2.8rem;

  @media ${media.mobileL} {
    width: 530px;
    font-size: 3.63rem;
    line-height: 4rem;
  }
`;

const HeaderTag = styled.p`
  font-size: 1.15rem;
  font-weight: bold;
  letter-spacing: 0.05rem;
`;

const HeaderCaption = styled.div`
  z-index: 1;
  position: relative;
  color: ${props => props.theme.white};
  text-align: right;
  padding-right: 3rem;
  padding-bottom: 2rem;
  font-size: 0.75rem;
  display: none;
  @media ${media.mobileL} {
    display: block;
  }
`;
/**
 *  Header Section used only on the home page. This includes the main image, navigation, book form
 */
class HomeHeader extends Component {
  render() {
    const props = this.props;

    const darkClass = props.darken && 'darker';

    const typeit = [
      'partying',
      'sleeping',
      'exploring',
      'chilling',
      'drinking',
      'eating'
    ];
    return (
      <Section
        id="header"
        padding="1.5rem 3rem"
        style={{
          margin: 0,
          position: 'relative',
          zIndex: 3,
          minHeight: '25rem'
        }}
      >
        <Img
          fluid={props.backgroundImage}
          alt={props.alt}
          className={darkClass}
          style={{
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            overflow: 'hidden'
          }}
        />

        {/* <video
        width="100%"
        height="auto"
        autoPlay
        poster={props.poster}
        muted
        loop
        style={{
          position: 'absolute',
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
          overflow: 'hidden',
          zIndex: 1,
          objectFit: 'cover'
        }}
      >
        <source src="http://www.stayatbase.com/video/base.mp4" />
      </video> */}
        <LangSwitch />
        <Navigation />

        <HeaderContainer padding="0rem">
          <PageTitle>{props.pageTitle}</PageTitle>
          <HeaderTag>{props.tagline}</HeaderTag>
          <HostelSearchForm hostels={props.hostels} />
        </HeaderContainer>

        <HeaderCaption>
          <div>{props.caption}</div>

          {props.propertyName && (
            <div>
              <div>@ {props.propertyName}</div>
            </div>
          )}
        </HeaderCaption>
      </Section>
    );
  }
}

HomeHeader.propTypes = {
  /** background image for the page */
  backgroundImage: PropTypes.object.isRequired,
  /** The title of the page. This is h1 for seo. It could be a hostel name or the name of a blog post  */
  pageTitle: PropTypes.string,
  /** This is the sub heading of the page */
  tagline: PropTypes.string,
  /** If required you can show a property name if there is a relationship */
  propertyName: PropTypes.string,
  /** allows a caption to be shown and appears in the lower right hand side of the header
   * will not show on mobile.
   */
  caption: PropTypes.string
  /** Allow the menu to be shown inverted, so dark logo and dark text */
};

export { HomeHeader };
