import React from 'react';
import Img from 'gatsby-image';
import Link from 'gatsby-link';
import PropTypes from 'prop-types';
import { FlexGalleryItem } from '../layout/index.js';
import LocalisedLink from './locallisedLink';

/**
 * Component for showing a grid based mosaic style gallery with clickable tiles  */
function FlexGallery(props) {
  return (
    <FlexGalleryItem
      className={`f-${props.index}`}
      basis={props.basis}
      basisWide={props.basisWide}
    >
      {props.id === 'bedhopper' ? (
        <LocalisedLink to={`${props.url}`}>
          <Img
            loading="lazy"
            fluid={props.fluid}
            alt={props.alt}
            style={{
              position: 'absolute',
              left: 0,
              top: 0,
              width: '100%',
              height: '100%',
              overflow: 'hidden',
              zIndex: 1
            }}
          />
          <h3>{props.title}</h3>
        </LocalisedLink>
      ) : (
        <LocalisedLink to={`${props.url}/`}>
          <Img
            fluid={props.fluid}
            loading="lazy"
            alt={props.alt}
            style={{
              position: 'absolute',
              left: 0,
              top: 0,
              width: '100%',
              height: '100%',
              overflow: 'hidden',
              zIndex: 1
            }}
          />
          <h3>{props.title}</h3>
        </LocalisedLink>
      )}
    </FlexGalleryItem>
  );
}

FlexGallery.propTypes = {
  /** URL prop allows linking fom image card using gatsby Link */
  url: PropTypes.string.isRequired,
  /** Is the text in the h3 element */
  title: PropTypes.string,
  /** Is the object from dato cms containing all the images required for Srcest */
  fluid: PropTypes.object.isRequired,
  /** The alt tag of the image required for SEO purposes */
  alt: PropTypes.string.isRequired,
  /** Changes the width of a tile */
  basis: PropTypes.bool,
  /** Changes the width of a tile on wider screens*/
  basisWide: PropTypes.bool,
  /** Assigns a uniuqe class to each tile */
  index: PropTypes.number,
  id: PropTypes.string.isRequired
};

export { FlexGallery };
