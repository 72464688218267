import React from 'react';
import PropTypes from 'prop-types';
import { Elements } from 'react-stripe-elements';
import { Draw, CloseButton } from '../layout/index.js';
import CheckoutForm from './CheckoutForm.js';

/** A component to hold the checkout form on the side of the product page */
const CheckoutDraw = props => {
  return (
    <Draw light open={props.open}>
      <header>
        <CloseButton title="close" onClick={props.toggleDraw}>
          &times;
        </CloseButton>
      </header>

      <div>
        <Elements>
          <CheckoutForm data={props.data} closeForm={props.toggleDraw} />
        </Elements>
      </div>
    </Draw>
  );
};

CheckoutDraw.propTypes = {
  /** determins if the menu is open or closed */
  open: PropTypes.bool,
  /** Trigger funtion on open and shutt the draw */
  toggleDraw: PropTypes.func
};

export { CheckoutDraw };
