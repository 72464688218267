import React from 'react';
import { Link } from 'gatsby';
import { LocaleContext } from '../layout';

// Use the globally available context to choose the right path
const LocalisedLink = ({ to, ...props }) => {
  const { locale } = React.useContext(LocaleContext);

  const prefix = locale === 'en' ? '' : `${locale}/`;

  // If it's the default language, don't do anything
  // If it's another language, add the "path"
  // However, if the homepage/index page is linked don't add the "to"
  // Because otherwise this would add a trailing slash
  const path = `${prefix}${to}`;

  return <Link {...props} to={path} />;
};

export default LocalisedLink;
