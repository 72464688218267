import React from 'react';
import Link from 'gatsby-link';
import styled from 'styled-components';
import useTranslations from './useTranslations';

const Switch = styled.div`
  position: relative;
  text-align: right;
  font-size: 14px;
  margin-bottom: 0.5rem;
  color: #fff;
  a {
    color: #fff;
  }
`;

// This component is used to switch between Languages
const LangSwitch = () => {
  const { english, chinese } = useTranslations();
  return (
    <Switch>
      <Link to="/" hrefLang="en">
        {english}
      </Link>
      {` `}/{` `}
      <Link to="/zh-CN" hrefLang="cn">
        {chinese}
      </Link>
    </Switch>
  );
};

export default LangSwitch;
