import React from 'react';
import PropTypes from 'prop-types';
import { Container, Section } from '../common';
import GoogleApiWrapper from './GoogleMapsContainer';
import styled from 'styled-components';
import useTranslations from '../layout/useTranslations';

const Mapbox = styled.div`
  position: relative;
  height: 16rem;
`;

const Contact = styled.div`
  margin-top: 1rem;
`;
const ContactDetails = styled.div`
  margin-bottom: 0.5rem;
`;

/**
 * Location Section on hostel pages
 */
const Location = props => {
  const { phone, email, address, location, thingsNearby } = useTranslations();

  return (
    <Section id="location" lightBlueBackground>
      <Container>
        <h2>{location}</h2>
      </Container>
      <Container col="2" mobcol="1fr" gap="2rem">
        <div>
          <Mapbox>
            <GoogleApiWrapper
              title={props.title}
              street={props.streetAddress}
              city={props.city}
              lat={props.latitude}
              long={props.longitude}
              placeholder={props.mapScreenShot}
            />
          </Mapbox>

          <Contact>
            <ContactDetails>
              <a
                target="_blank"
                rel="noopener noreferrer "
                href="https://www.google.com/maps/dir/?api=1&destination=base+backpackers+sydney"
              >
                Get directions
              </a>
            </ContactDetails>
            <ContactDetails>
              {address}: {props.streetAddress}, {props.city}
            </ContactDetails>
            <ContactDetails>
              {phone}: {props.phone}
            </ContactDetails>
            <ContactDetails>
              {email}: {props.emailAddress}
            </ContactDetails>
          </Contact>
        </div>

        <div>
          <h3>{thingsNearby}</h3>
          {props.thingsNearBy.map(near => (
            <div key={near.id}>
              <h4>
                {near.time} {near.tripType}
              </h4>
              <p>{near.name}</p>
            </div>
          ))}
        </div>
      </Container>
    </Section>
  );
};

Location.propTypes = {
  /** Passes hostel name to the map component */
  title: PropTypes.string,
  /** Passes Street Address to the map component */
  streetAddress: PropTypes.string.isRequired,
  /** Passes Lat to the map component */
  latitude: PropTypes.number.isRequired,
  /** Passes long to the map component */
  longitude: PropTypes.number.isRequired,
  /** Passes Lat to the map component */

  thingsNearBy: PropTypes.array.isRequired,
  /** Add the city name to the map component */

  city: PropTypes.string.isRequired,
  /** Pass the phone number for the location */

  phone: PropTypes.string.isRequired,
  /** Pass the email for the location */

  emailAddress: PropTypes.string.isRequired,
  /** Pass the Screen shot for the map for the location . This will lazly load the image first*/

  mapScreenShot: PropTypes.object.isRequired
};

export { Location };
