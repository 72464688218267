import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { Section, Container, Button } from '../common/';
import { Navigation, Gal } from '../layout/';
import media from '../layout/Media';
import useTranslations from '../layout/useTranslations';
import LangSwitch from './LangSwitch';

const HeaderContainer = styled(Container)`
  z-index: 1;
  position: relative;
  color: ${props => props.theme.white};
  margin-top: 5rem;
`;

const PageTitle = styled.h1`
  width: 100%;
  font-size: 2.63rem;
  line-height: 3rem;

  @media ${media.laptop} {
    width: 530px;
    font-size: 3.63rem;
    line-height: 4rem;
  }
`;

const HeaderButton = styled(Button)`
  border: 2px solid ${props => props.theme.primaryColor};
  @media ${media.mobileL} {
    width: 200px;
  }
`;

const HeaderTag = styled.p`
  font-size: 1.15rem;
  font-weight: bold;
  letter-spacing: 0.05rem;
`;

const HeaderCaption = styled.div`
  z-index: 1;
  position: relative;
  color: ${props => props.theme.white};
  text-align: right;
  padding-right: 3rem;
  padding-bottom: 2rem;
  font-size: 0.75rem;
  display: none;

  @media ${media.mobileL} {
    display: block;
  }
`;

/** Header Section used on most page of the website
 * The image is supplied by dato CMS. it's recommended that this image be manipulated to show darker tones on the lower left portion an under the top nav to ensure the text pops
 */
const Header = props => {
  const { bookNow, viewGallery } = useTranslations();

  const buttonThere = props.button;

  let button;

  switch (buttonThere) {
    case 'hostel':
      button = (
        <p>
          <HeaderButton primary className="distributor">
            {bookNow}
          </HeaderButton>
          &nbsp;
          <Gal images={props.gal} button={viewGallery} />
        </p>
      );
      break;
    case 'group':
      button = (
        <p>
          <a href="#bookingform">
            <HeaderButton primary>{bookNow}</HeaderButton>
          </a>
          &nbsp;
          <Gal images={props.gal} />
        </p>
      );
      break;
    default:
      null;
  }

  const darkClass = props.darken && 'darker';
  return (
    <Section
      id="header"
      padding="1.5rem 3rem"
      style={{
        margin: 0,

        position: 'relative',
        zIndex: 3,
        minHeight: '25rem'
      }}
    >
      <Img
        fluid={props.backgroundImage}
        alt={props.alt}
        className={darkClass}
        style={{
          position: 'absolute',
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
          overflow: 'hidden'
        }}
      />
      <LangSwitch />
      <Navigation hostel={props.hostel} darkmenu={props.menuDark} />

      <HeaderContainer padding="0rem">
        <PageTitle>{props.pageTitle}</PageTitle>
        <HeaderTag>{props.tagline}</HeaderTag>
        {button}
      </HeaderContainer>

      <HeaderCaption>
        <div>{props.caption}</div>

        {props.propertyName && (
          <div>
            <div>@ {props.propertyName}</div>
          </div>
        )}
      </HeaderCaption>
    </Section>
  );
};

Header.propTypes = {
  /** background image for the page */
  backgroundImage: PropTypes.object.isRequired,
  /** The title of the page. This is h1 for seo. It could be a hostel name or the name of a blog post  */
  pageTitle: PropTypes.string,
  /** This is the sub heading of the page */
  tagline: PropTypes.string,
  /** If required you can show a property name if there is a relationship */
  propertyName: PropTypes.string,
  /** allows a caption to be shown and appears in the lower right hand side of the header
   * will not show on mobile.
   */
  caption: PropTypes.string,
  /** Allow the menu to be shown inverted, so dark logo and dark text */
  menuDark: PropTypes.bool,
  /** Allow to specify if this will be a hostel page and adjust the formatting accordingly */
  hostel: PropTypes.bool
};

export { Header };
