import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { Section, Container, Button } from '../common/';
import { Navigation, GroupsForm, Tag } from '../layout/';
import media from '../layout/Media';
import parseQueryString from './../../lib/parseQueryString.js';

const HeaderContainer = styled.div`
  z-index: 1;
  position: relative;
  color: ${props => props.theme.white};
  margin-top: 5rem;
  display: flex;
  flex-wrap: wrap;
  @media ${media.laptop} {
    max-height: 550px;
    max-width: 1000px;
    margin: 5rem auto 0;
  }
`;

const HeaderText = styled.div`
  flex-basis: 45%;
  flex-grow: 1;
  flex-shrink: 1;
  @media ${media.laptop} {
    margin-right: 2rem;
  }
`;

const PageTitle = styled.h1`
  width: 100%;
  font-size: 2.63rem;
  line-height: 3rem;

  @media ${media.laptop} {
    font-size: 3.63rem;
    line-height: 4rem;
  }
`;

const HeaderTag = styled.p`
  font-size: 1.15rem;
  font-weight: bold;
  letter-spacing: 0.05rem;
`;

const Perfect = styled.p`
  margin-bottom: 0.5rem;
`;

/** Header Section used on the groups page of the website. Ideally a refactor could be done here to just use one header component
 * The image is supplied by dato CMS. it's recommended that this image be manipulated to show darker tones on the lower left portion an under the top nav to ensure the text pops
 */
class GroupsHeader extends Component {
  render() {
    const props = this.props;
    const darkClass = props.darken && 'darker';

    let urlQueries = parseQueryString(props.query);
    let target = urlQueries.target;
    let source = urlQueries.source;

    return (
      <Section
        id="header"
        padding="1.5rem 3rem"
        style={{
          margin: 0,

          position: 'relative',
          zIndex: 3,
          minHeight: '25rem'
        }}
      >
        <Img
          fluid={props.backgroundImage}
          alt={props.alt}
          className={darkClass}
          style={{
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            overflow: 'hidden'
          }}
        />

        <Navigation darkmenu={this.props.menuDark} />

        <HeaderContainer>
          <HeaderText>
            <PageTitle>
              {target} {props.pageTitle}
            </PageTitle>
            <HeaderTag>
              The experts at hosting large group bookings in{' '}
              {this.props.location}
            </HeaderTag>
            {!target && (
              <div>
                <Perfect>Perfect for</Perfect>
                <Tag>
                  {this.props.idealFor.map(ideal => (
                    <li key={ideal.id}>{ideal.idealForTag}</li>
                  ))}
                </Tag>
              </div>
            )}
          </HeaderText>

          <GroupsForm
            link={this.props.link}
            propertyName={this.props.propertyName}
            priceFrom={this.props.priceFrom}
            source={source}
            data={this.props.data}
          />
        </HeaderContainer>
      </Section>
    );
  }
}

GroupsHeader.propTypes = {
  /** background image for the page */
  backgroundImage: PropTypes.object.isRequired,
  /** The title of the page. This is h1 for seo. It could be a hostel name or the name of a blog post  */
  pageTitle: PropTypes.string,
  /** This is the sub heading of the page */
  tagline: PropTypes.string,
  /** If required you can show a property name if there is a relationship */
  propertyName: PropTypes.string,
  /** allows a caption to be shown and appears in the lower right hand side of the header
   * will not show on mobile.
   */
  caption: PropTypes.string,
  /** Allow the menu to be shown inverted, so dark logo and dark text */
  menuDark: PropTypes.bool,
  /** Allow to specify if this will be a hostel page and adjust the formatting accordingly */
  hostel: PropTypes.bool
};

export { GroupsHeader };
